<template>
  <div>
    <b-loading :is-full-page="false" :active.sync="loading" />
    <form class="form width500 centered" @submit.prevent="submit">
      <b-field
        :label="$t('fields.email')"
        :type="{'is-danger': errors.has('email')}"
        :message="errors.first('email')"
      >
        <b-input
          v-model="auth.username"
          v-validate="{
            required: true,
            email: {
              allow_utf8_local_part: false
            }
          }"
          type="email"
          name="email"
          size="is-medium"
        />
      </b-field>
      <b-field
        :label="$t('fields.password')"
        :type="{'is-danger': errors.has('password')}"
        :message="errors.first('password')"
      >
        <b-input
          v-model="auth.password"
          v-validate="'required'"
          type="password"
          password-reveal
          name="password"
          size="is-medium"
        />
      </b-field>
      <div class="buttons-set m-t-30">
        <button type="submit" class="button is-success is-large is-fullwidth" :disabled="loading">
          {{ $t('login.button_send') }}
        </button>
      </div>

      <socials @loading="x => loading = x" />

      <ul class="center m-b-20 m-t-30">
        <li>
          <nuxt-link v-if="!seller" :to="localePath('auth-register')">
            {{ $t('login.register') }}
          </nuxt-link>
          <nuxt-link v-if="seller" :to="localePath('auth-register-seller')">
            {{ $t('login.register_seller') }}
          </nuxt-link>
        </li>
        <li>
          <nuxt-link :to="localePath('auth-forgot-password')">
            {{ $t('forgotPassword.link') }}
          </nuxt-link>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import Socials from '~/marketplace-front-core/components/auth/Socials'
import loginMixin from '~/marketplace-front-core/mixin/login'

export default {
  components: { Socials },
  mixins: [loginMixin],
  props: {
    seller: {
      type: Boolean
    }
  },
  data () {
    return {
      auth: {
        username: '',
        password: ''
      },
      loading: false
    }
  },
  methods: {
    submit () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true
          this.$api.auth.login(this.auth)
            .then((res) => {
              this.successLogin(res)
            })
            .catch(({ response }) => {
              this.errorLogin(response)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
