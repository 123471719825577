<template>
  <div>
    <b-button type="is-facebook" icon-left="facebook" class="is-fullwidth" @click.prevent="login">
      {{ $t('login.social.facebook') }}
    </b-button>
  </div>
</template>

<script>
import loginMixin from '~/marketplace-front-core/mixin/login'

export default {
  mixins: [loginMixin],
  props: {
    noRedirect: {
      type: Boolean
    }
  },
  data () {
    return {
      facebookId: process.env.facebookId
    }
  },
  mounted () {
    const vm = this
    window.fbAsyncInit = function () {
      FB.init({
        appId: vm.facebookId,
        cookie: true,
        xfbml: true,
        version: 'v8.0'
      })

      FB.AppEvents.logPageView()
    };

    (function (d, s, id) {
      let js; const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) { return }
      js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  },

  methods: {
    login () {
      FB.login(this.statusChangeCallback, { scope: 'email', return_scopes: true })
    },
    statusChangeCallback (response) {
      if (response.status === 'connected') {
        this.$emit('loading', true)
        this.$api.auth.social.facebook({ accessToken: response.authResponse.accessToken })
          .then((res) => {
            this.successLogin(res, this.noRedirect)
            this.$emit('success', true)
          })
          .catch(() => {
            this.errorLogin()
          })
          .finally(() => {
            this.$emit('loading', false)
          })
      } else {
        this.errorLogin()
      }
    }
  }
}
</script>
