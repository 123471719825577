<template>
  <div>
    <page-title>
      {{ $t('login.title') }}
    </page-title>
    <login />
  </div>
</template>

<script>
import Login from '~/marketplace-front-core/components/auth/Login'

export default {
  components: { Login },
  asyncData ({ app, store }) {
    store.dispatch('page/update', { name: 'breadcrumbs', data: [{ url: null, text: app.i18n.t('login.title') }] })
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      title: this.$t('login.title'),
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        ...i18nHead.meta
      ],
      link: [
        ...this.metaLink(i18nHead.link)
      ]
    }
  },
  meta: { auth: 'false' }
}
</script>
