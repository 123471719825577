<template>
  <div>
    <b-button :id="buttonId" icon-left="google" type="is-google" class="is-fullwidth">
      {{ $t('login.social.google') }}
    </b-button>
  </div>
</template>

<script>
import loginMixin from '~/marketplace-front-core/mixin/login'

export default {
  mixins: [loginMixin],
  props: {
    noRedirect: {
      type: Boolean
    }
  },
  data () {
    return {
      buttonId: `google-login-${Math.random().toString(36).substr(2)}`,
      googleId: process.env.googleId,
      script: null
    }
  },
  mounted () {
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/api:client.js'
    script.onload = () => { this.init() }
    this.script = document.head.appendChild(script)
  },
  beforeDestroy () {
    document.head.removeChild(this.script)
  },
  methods: {
    init () {
      const vm = this
      let auth2

      gapi.load('auth2', function () {
        auth2 = gapi.auth2.init({
          client_id: vm.googleId,
          cookiepolicy: 'single_host_origin'
        })
        attachSignin(document.getElementById(vm.buttonId))
      })

      function attachSignin (element) {
        auth2.attachClickHandler(element, {}, vm.handleSuccess, vm.handleFailure)
      }
    },
    handleSuccess (googleUser) {
      this.$emit('loading', true)
      this.$api.auth.social.google({ accessToken: googleUser.getAuthResponse().id_token })
        .then((res) => {
          this.successLogin(res, this.noRedirect)
          this.$emit('success', true)
        })
        .catch(() => {
          this.errorLogin()
        })
        .finally(() => {
          this.$emit('loading', false)
        })
    },
    handleFailure () {
      this.errorLogin()
    }
  }
}
</script>
